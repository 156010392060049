import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IProduct } from 'src/app/shared/interfaces/product';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit {
  @Input() product: IProduct
  imagePath = environment.IMAGE_PATH;

  constructor(public bsModalRef: BsModalRef, private router: Router) { }

  ngOnInit() {}

  toThePaymentOfTheCart() {
    this.bsModalRef.hide();
    this.router.navigate(['public/shop/cart']);
  }
}
