import { Pipe, PipeTransform } from '@angular/core';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';

@Pipe({
  name: 'daysFormat'
})
export class DaysFormatPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return format(new Date(value), 'dd MMMM yyyy à HH:mm', { locale: fr});
  }
}
