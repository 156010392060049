import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IArticle } from '../interfaces/article';
import { IComment } from '../interfaces/comments';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  baseUrl = environment.API_URL;

  articles: IArticle[] = []
  articlesSubject = new Subject<IArticle[]>();
  singleArticleById = false;
  // private articleCreated = new Subject<string>();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor( private httpClient: HttpClient ) {
    // Lorsqu'on injecte le service il chargera les articles en premier
    this.getArticlesFromServer();
  }

  emitArticles() {
    this.articlesSubject.next(this.articles);
  }

  /** @desc GESTION DES ARTICLES */

  /**
   *  @desc Get all articles from server
   */
  getArticlesFromServer(): Observable<IArticle[]> {
    this.httpClient.get<IArticle[]>(`${this.baseUrl}/articles`, this.httpOptions).subscribe(
      (data: IArticle[]) => {
        if(data) {
          this.articles = data;
          this.emitArticles();
        } else {
          console.log('Erreur du serveur sur la récupéraration des produits');
        }
      }
    );
    return
  }

  getArticleById(id: string): Observable<IArticle> {
    // this.singleArticleById = true;
    return this.httpClient.get<IArticle>(`${this.baseUrl}/articles/${id}`);
  }

  createArticle(article: IArticle) {
    return this.httpClient.post<IArticle>(`${this.baseUrl}/article`, article, this.httpOptions);
  }


  deleteSingleArticle(id: string[]) {
    return this.httpClient.delete(`${this.baseUrl}/articles/${id}`);
  }

  deleteAllArticle(ids: string[]) {
    const allIds = ids.join(',');
    return this.httpClient.delete(`${this.baseUrl}/articles?ids=${allIds}`);
  }

  uploadImage(formData: FormData) {
    return this.httpClient.post<IArticle>(`${this.baseUrl}/article/image`, formData);
  }

  updateArticle(id: string, article: IArticle) {
    return this.httpClient.put(`${this.baseUrl}/edit/${id}`, article);
  }

  dispatchArticleCreated(id: string) {
    // this.articleCreated.next(id);
  }

  /** @desc GESTION DES COMMENTAIRES */

  createComment(comment: IComment) {
    // console.log(`Méthode du service : Le commentaire suivant est valide`, comment);
    // return this.httpClient.post<IComment>(`${this.baseUrl}/article/${comment.article_id}/comment`, comment, this.httpOptions);
    return this.httpClient.post<IComment>(`${this.baseUrl}/article/comment`, comment, this.httpOptions);
  }
}
