import { Injectable } from '@angular/core';
import { element } from 'protractor';
import { Cart } from '../interfaces/cart';
import { IProduct } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart: Cart[] = [];
  // cartData sera mis à jour à chaque ajout ou suppression d'un produit
  cartData = {
    numberProductInCart: 0,
    totalPriceOfCart: 0
  }

  constructor() { }

  /**
   * @desc Updates the cart and its list of products
   */
  updateDataCart() {
    let numberProductInCart: number = 0; // Nombre d'éléments dans le panier
    let totalPriceOfCart: number = 0; // Prix total du panier
    this.cart.forEach(element => {
      // += permet d'ajouter dans le tableau. Sans cela l'article remplacerait un article déjà présent dans le panier
      numberProductInCart += element.number;
      totalPriceOfCart += element.product.price*element.number;
    });
    this.cartData.numberProductInCart = numberProductInCart;
    this.cartData.totalPriceOfCart = totalPriceOfCart;
  }

  /**
   * @desc Add product in the cart
   * @param newProduct
   */
  addProductToCart(newProduct: IProduct): void {
    const checkedProduct: Cart = this.cart.find(element => element.product == newProduct);
      if(checkedProduct) {
        checkedProduct.number++;
      } else {
        const newProductAddToCart = {
          number: 1,
          product: newProduct
        };
        this.cart.push(newProductAddToCart)
    }
  this.updateDataCart();
  }

  /**
   * @desc
   * @param productToDelete
   */
  deleteProductFromCart(productToDelete: IProduct): void {
    // Indice du produit à supprimer
    const indexProduct = this.cart.findIndex(element => element.product == productToDelete);
    if(indexProduct) {
      if(this.cart[indexProduct].number>1) {
        this.cart[indexProduct].number--;
      } else {
        this.cart.splice(indexProduct);
      }
    }
    this.updateDataCart();
  }
}
