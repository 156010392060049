import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { IUser } from '../interfaces/user';

@Injectable()
export class AddCookieInterceptor implements HttpInterceptor {

    /**
     * @desc this method is passed to Providers[] in the app.module.ts file
     */
    intercept(req: HttpRequest<IUser>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(`AddCookieInterceptor intercepted ${req.url} with method ${req.method}`);
        const reqWithCookie: HttpRequest<IUser> = req.clone({
            withCredentials: true
        });
        console.log('Interceptor', reqWithCookie);
        return next.handle(reqWithCookie);
    }
}
