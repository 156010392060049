import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './public/components/home/home.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'public',
    loadChildren: () => import('../app/public/public.module').then(mod => mod.PublicModule)
    // Ajouter ici le canActivate
  },
  {
    path: 'protected',
    loadChildren: () => import('../app/protected/protected.module').then(mod => mod.ProtectedModule)
    // Ajouter ici le canActivate
  }
];

@NgModule({
  imports: [
    // forRoot() spécifie qu'il s'agit du module de routing racine
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
