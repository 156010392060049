import { NgModule, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Public component
import { HeaderComponent } from './public/components/header/header.component';
import { NavbarComponent } from './public/components/navbar/navbar.component';
import { FooterComponent } from './public/components/footer/footer.component';

// Import du Shared Module qui contient ngx-bootstrap & ng-sidebar
import { SharedModule } from './shared/shared.module';

// Library
import { ToastrModule } from 'ngx-toastr';
import { AddCookieInterceptor } from './shared/interceptors/add-cookie';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      progressBar: true,
      closeButton: true,
      preventDuplicates: true,
    }),
    CarouselModule.forRoot()
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddCookieInterceptor, multi: true }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
