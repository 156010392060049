import { Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IUser, User } from "../interfaces/user";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { Observable, Subject, Subscription } from "rxjs";
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  baseUrl = environment.API_URL;
  userConnected: IUser = <IUser>{};
  userSubject = new Subject<IUser>();
  isAuthenticated = false;

  constructor(private httpClient: HttpClient, private toastr: ToastrService, private router: Router) {}

  /**
   *
   */
  emitUser() {
    this.userSubject.next(this.userConnected);
  }

  /**********
   * REQUEST *
   **********/

  /**
   * @param user;
   * @desc Allows the registration of a new user
   */
  public createUser(user: IUser) {
    user.inscriptionDate = new Date;
    return this.httpClient.post<IUser>(`${this.baseUrl}/users`, user);
  }

  /**
   * @desc Return an user with his id
   * @exampleURL http://localhost:3000/users/2
   */
  public getUser(): Observable<User>{
    return this.httpClient.get<IUser>(`${environment.API_URL}users/:id`).pipe(
      map((response: IUser) => {
        return new User(response);
      })
    );
  }

  /**
   * @desc Authentification Service with PROMISE
   * @exampleURL http://localhost:3000/users?&username=CannaMan&password=password
   * @explanation Les méthodes GET, PUT... renvoient des Observables, avec la méthode toPromise(), cela
   * permet de transformer l'Observable en promise.
   * Une PROMISE produit une seule valeur non annulable, possède un traitement immédiat
   * et deux méthodes uniquement (then/catch).
   * Un OBSERVABLE produit un "stream" de valeurs (potentiellement infini) annulable.
   * Tant qu'on ne s'inscrit pas à l'"observable" avec la méthode susbscribe(), il ne se passe rien car cet observable est "lazy“.
   * @param newUser
   */
  public authentification(newUser: { password: string; username: string }) {
    return new Promise<IUser>(
      (resolve, reject) => {
      const url = `${this.baseUrl + "/users?"}` + "&username=" + newUser.username + "&password=" + newUser.password;
        this.httpClient.get<IUser>(url)
        .toPromise()
        .then(
          (response: IUser) => {
            if(response[0].username === newUser.username && response[0].password === newUser.password) {
              this.userConnected = response[0];
              this.isAuthenticated = true;

              /*************************************************
               * GESTION DU LOCALSTORAGE ET DE SESSION STORAGE *
               ************************************************/

              //*********************************************************************************************************
              // LocalStorage stocke les informations localement sur votre système. Ainsi, pour stocker les informations
              // utilisateur telles que login et password, on utilise LocalStorage.
              //*********************************************************************************************************
              window.localStorage.setItem('username', response[0].username);
              window.localStorage.setItem('password', response[0].password);
              window.localStorage.setItem('category', response[0].userCategory);
              // ********************************************************************************************************************
              // sessionStorage quant à lui stocke les informations pour la session en cours. Ainsi les informations de l'utilisateur
              // actuellement connecté seront visibles dans le SessionStorage (user, cart..). Si l'utilisateur modifie l'onglet
              // actuel ou ferme la fenêtre du navigateur, la session expire et les informations sont automatiquement perdues.
              // ********************************************************************************************************************
              const userSessionStorage = JSON.stringify(this.userConnected)
              sessionStorage.setItem('user', userSessionStorage);
              var obj = JSON.parse(sessionStorage.user);

              this.emitUser();
              resolve(response);
            } else {
              reject(false);
            }
          }
        )
        .catch(
          (error) => {
          reject(false);
        }
      );
  });
}

  /**
   * @todo Vider le localstorage ou le session storage (A voir)
   * @desc Allows the user to log out
   */
  public logout() {
    this.isAuthenticated = false;
    this.handleSuccess('Merci de votre visite ' + `${this.userConnected.username}`)
    this.router.navigate(['public/home']);
  }

  /**
   * @todo Build the function with backend and nodemailer.
   * @desc Allows the user to log out
   * @todo Make a request for the users having lost their identifiers
   */
  public credentialsRecovery(emailForm: any) {
    // créer la fonction qui récupérera chaque mail afin de vérifier l'email entré par l'utilisateur
    // Vérifier si l'email correspond bien à un utilisateur
    if(emailForm) {
      console.log("Envoi d'un mail de récupération des identifiants", emailForm.value);
    } else {
      this.handleError('Votre email est inconnu de nos services')
    }
  }

  /**
   * @desc Success Alert Function
   * @param message
   */
  handleSuccess(message: any) {
    this.toastr.success(message);
  }

  /**
   * @desc Error Alert Function
   * @param message
   */
  handleError(message: any) {
    this.toastr.error(message);
  }

  /**
   * @desc Returns the account types for the form's select
   */
  public loadTypeProfil(): any {
    const profils: any = [
      { nameProfil: "Consommateur" },
      { nameProfil: "Producteur" }
    ];
    return profils;
  }
}
