import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Comment, IComment } from '../../interfaces/comments';
import { ArticlesService } from '../../services/articles.service';
import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-comments-system',
  templateUrl: './comments-system.component.html',
  styleUrls: ['./comments-system.component.scss']
})
export class CommentsSystemComponent implements OnInit {

  articleId: string;
  author: string;
  commentContent: FormControl;
  commentForm: FormGroup;
  commentActive = false;

  constructor(  private fb: FormBuilder,
                private userService: UsersService,
                private articleService: ArticlesService,
                private activateRoute: ActivatedRoute,
                private toastr: ToastrService
  ) { }

  ngOnInit() {
  this.articleId = this.activateRoute.snapshot.paramMap.get('id');
  this.author =  localStorage.getItem('username');
  this.commentContent = new FormControl('', [Validators.required]);
  this.commentForm = this.fb.group({
    content: this.commentContent
  });
}

/** @desc Envoi le commentaire saisi */
public submitComment() {
  if (this.commentForm.valid) {
    console.log('Valeur du commentaire', this.commentForm.value);
    const newComment = new Comment(this.author, this.commentContent.value);
    this.articleService.createComment(newComment).subscribe(
      data => this.handleSuccess(data),
      error => this.handleError(error));
    }
  }

  /** @desc Méthode qui active ou désactive le champ textarea une fois loggé */
  public toggleComment(): void {
    if (this.userService.isAuthenticated) {
      this.commentActive = !this.commentActive;
    } else if (!this.userService.isAuthenticated) {
      this.toastr.error('Vous devez être connecté');
    }
  }

  handleSuccess(data: IComment) {
    this.toastr.success('Votre commentaire a été posté avec succès');
    this.commentForm.reset();
    this.commentActive = false;
  }

  handleError(error: any) {
    this.toastr.error('Le commentaire suivant n\'a pas été envoyé : ', error);
  }
}
