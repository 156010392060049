export interface IComment {
    author: string;
    content: string;
    creation_date: Date;
}

export class Comment {
    author: string;
    content: string;
    creation_date: Date;

    constructor(author: string, content: string) {
        this.author = author;
        this.content = content;
    }
}

