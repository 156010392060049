import { NgModule } from '@angular/core';

// Add here ngx-bootstrap import
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
declarations: [],
// Completed table with import :
imports: [
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot()
   ],
   // Completed table with exportation also :
   exports: [
    AlertModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PopoverModule,
    CollapseModule,
    AccordionModule,
    CarouselModule,
    ButtonsModule,
    TooltipModule
   ]
 })
 export class NgxBootstrapModule { }
