import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { NgxEditorModule } from 'ngx-editor';
import { NgxBootstrapModule } from './modules/ngx-bootstrap.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConnexionComponent } from '../public/modals/connexion/connexion.component';
import { RouterModule } from '@angular/router';
// Pipes
import { DaysAgoPipe } from './pipes/days-ago.pipe';
import { DaysFormatPipe } from './pipes/days-format.pipe';
import { CommentsSystemComponent } from './components/comments-system/comments-system.component';
import { CommentsListComponent } from './components/comments-system/comments-list/comments-list.component';
import { AddToCartComponent } from '../public/modals/add-to-cart/add-to-cart.component';

@NgModule({
  imports: [
    CommonModule,
    NgxBootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // NgxEditorModule
  ],
  declarations: [
    ConnexionComponent,
    AddToCartComponent,
    DaysAgoPipe,
    DaysFormatPipe,
    CommentsSystemComponent,
    CommentsListComponent,
  ],
  exports: [
    CommonModule,
    NgxBootstrapModule, // Export de NGX afin qu'il soit dispo dans toutes l'application
    FormsModule,
    ReactiveFormsModule,
    DaysAgoPipe,
    DaysFormatPipe,
    CommentsSystemComponent,
    CommentsListComponent
  ],
  entryComponents: [
    ConnexionComponent
  ]
})

export class SharedModule { }
