import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { IComment } from 'src/app/shared/interfaces/comments';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { ActivatedRoute } from '@angular/router';
import { IArticle } from 'src/app/shared/interfaces/article';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent implements OnInit {
  @Input() comments: IComment;
  // public comments: Array<IComment> = [];
  // article: IArticle;

  constructor( private activatedRoute: ActivatedRoute, private articleService: ArticlesService ) {  }

  ngOnInit() {
    // this.comments = this.article.comments
    // console.log('Message de @Input ======> ', this.comments);

    // const id = this.activatedRoute.snapshot.paramMap.get(`id`);
    // this.articleService.getArticleById(id).subscribe(
    //   data => {
    //     this.article = data;
    //     console.log('Détail de l\'article du comments-list :', this.article);
    //   },
    //   error => console.error(error),
    // );
  }

}
