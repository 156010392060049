import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { UsersService } from 'src/app/shared/services/users.service';
import { IUser } from 'src/app/shared/interfaces/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {
  connexionForm: FormGroup;
  emailForm: FormGroup;
  inputPassword = false;
  regexUsername = '[ _0-9a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\'s-]*';

  constructor(
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private router: Router,
    private userService: UsersService ) {}

  ngOnInit() {
    this.initConnexionForm();
    this.initEmailForm();
  }

  /**
   * @desc Initialization of the connexion form
   */
  initConnexionForm() {
    this.connexionForm = this.formBuilder.group({
      username: this.formBuilder.control('', [Validators.required, Validators.pattern(this.regexUsername), Validators.maxLength(15)]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)])
    });
  }

  /**
   * @desc Initialization of the email form
   */
  initEmailForm() {
    this.emailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  /**
   *@desc Send the request for login and reset the form
   */
  submitConnexionForm() {
    const username = this.connexionForm.get('username').value;
    const password = this.connexionForm.get('password').value;
    const credentialUser: IUser = {username: username, password: password};
    this.userService.authentification(credentialUser)
    .then(
      (userConnected: IUser) => {
        if(userConnected[0].username === username && userConnected[0].password === password) {
          this.userService.handleSuccess(`Nous sommes ravis de vous revoir ` + `${userConnected[0].username}.`);
        }
      })
    .catch(
      (error) => {
        this.userService.handleError('Désolé ' + `${username}` + ', votre tentative de connexion a échouée.');
        this.router.navigate(['public/signup'])
      }
    )
    this.connexionForm.reset();
    this.bsModalRef.hide();
  }

  /**
   * @desc Display the email input
   */
  lostPassword() {
    this.inputPassword = true;
  }

  /**
   * @desc Sending an email to retrieve the credentials
   * @todo Créer la méthode d'envoi d'un email avec les identifiants
   */
  sendEmail(emailForm: FormGroup) {
    if (this.emailForm.valid) {
      this.userService.credentialsRecovery(emailForm);
      this.userService.handleSuccess('Un email de récupération des vos identifiants vient d\'être envoyé.');
    } else {
      console.error('Not valid');
      this.router.navigate(['public/home']);
    }
    this.emailForm.reset();
    this.bsModalRef.hide();
  }
}
