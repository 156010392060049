export interface IUser {
    id?: number;
    userCategory?: string;
    license?: string;
    firstname?: string;
    lastname?: string;
    username: string | null;
    password: string;
    email?: string;
    phone?: string;
    birthDate?: Date;
    inscriptionDate?: Date;
    billing_address?: {
        number: number;
        street: string;
        city: string;
        zipCode: number;
        state: string;
    };
    delivery_address?: {
        number: number;
        street: string;
        city: string;
        zipCode: number;
        state: string
    };
}

export class User implements IUser {
    id = null;
    userCategory = '';
    license = '';
    firstname = '';
    lastname = '';
    username = '';
    password = '';
    email = '';
    phone = '';
    birthDate = null;
    inscriptionDate = null;
    billing_address = {
        number : 0,
        street : '',
        city : '',
        zipCode : 0,
        state : ''
    };
    delivery_address = {
        number : 0,
        street : '',
        city : '',
        zipCode: 0,
        state : ''
    };

    // constructor(data : UserI | null = null){
    constructor(data : IUser | {} = {}) {
      Object.assign(this, data);
      // Nous aurions pu écrire :
      //    if(data){
      //     this.id = (data.id) ? data.id : null;
      //     this.username = (data.username) ? data.username : null;
      //     etc ...
      //    }
      // }
  }
}

