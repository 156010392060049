import { AfterViewInit, Component, OnInit } from '@angular/core';
// Import du component modal-connexion
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConnexionComponent } from '../../modals/connexion/connexion.component';

import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';
import { Cart } from 'src/app/shared/interfaces/cart';
import { CartService } from 'src/app/shared/services/cart.service';
import { IProduct } from 'src/app/shared/interfaces/product';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public isCollapsed = true;
  public bsModalRef: BsModalRef;
  public config = {
    animated: true
  };
  public userCategory: string;
  public cart: Cart[] = []
  public cartData;

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    public userService: UsersService,
    private cartService: CartService,
    private router: Router ) { }

  ngOnInit() {
    this.userCategory = localStorage.getItem('category');
    this.cart = this.cartService.cart;
    this.cartData = this.cartService.cartData;
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['public/home']);
  }

  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(ConnexionComponent, this.config);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  handleSuccess() {
    this.toastr.success('Vous êtes déconnecté avec succès');
  }

  handleError() {
    this.toastr.error('Vous n\'êtes pas déconnecté');
  }
}
